<template>
  <div class="con">
    <img class="code" src="https://www.guanyun-tech.com/img/code.png" alt="" />
    <div class="tip">{{ $t('footer.follow') }}</div>
    <div class="img">
      <img class="img_item" src="https://www.guanyun-tech.com/img/wb.png" alt="" />
      <img class="img_item" src="https://www.guanyun-tech.com/img/tw.png" alt="" />
      <img class="img_item" src="https://www.guanyun-tech.com/img/fb.png" alt="" />
    </div>
    <div class="addrs">
      <div class="text">{{ $t('footer.email') }}</div>
      <div class="text">{{ $t('footer.home') }}</div>
      <div class="text">{{ $t('footer.address1') }}</div>
      <div class="text">{{ $t('footer.address2') }}</div>
    </div>
    <div class="msg">
      <div class="msg_item">{{ $t('footer.copyright') }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "WebsiteBottom",

  data() {
    return {};
  },

  mounted() { },

  methods: {},
};
</script>

<style scoped>
.con {
  width: 100%;
  background-color: #000000;
  /* height: 9.06rem; */
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  padding-bottom: .59rem;
}

.code {
  width: 2.23rem;
  height: 2.22rem;
  margin-top: 0.72rem;
}

.tip {
  font-size: 0.2rem;
  font-weight: 500;
  color: #ffffff;
  margin-top: 0.42rem;
}

.img {
  display: flex;
  width: 50%;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.42rem;
}

.img_item {
  height: 0.72rem;
  width: 0.72rem;
}

.text {
  font-size: 0.2rem;
  font-weight: 500;
  color: #ffffff;
  margin-top: .1rem;
  padding: 0 .5rem;
  text-align: left;
}

.msg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: .52rem;
  padding: 0 .5rem;
  text-align: center;
}

.msg_item {
  font-size: 0.16rem;
  font-weight: 500;
  color: #808080;
  margin-top: .1rem;


}

.addrs {
  margin-top: .42rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>