<template>
  <div id="app">
   <Head/>
    <router-view/>
    <Bottom/>
  </div>
</template>
<script>
import Head from "@/components/head.vue"
import Bottom from "@/components/bottom.vue"
export default {
  components:{Head,Bottom}
}
</script>
<style>
#app{
  width: 100%;
  height: 100%;
}
</style>
