// en-US.js
export default {
    head: {
        menu:'menu',
        homepage: "About US",
        productcenter: 'Products',
        ZhishenIntelligentProtectiveBelt: 'Smart Protection',
        MotionCapture: 'Motion Capture',
        SmartFactory: "Smart Factory",
        MotionOrPostureMonitoring: 'Motion Monitoring',
        StructuralMonitoring: 'Structural Monitoring',
        NewsCenter: 'News Center',
        JoinUs: "Join Us",
    },
    footer: {
        email: "Email: info@guanyun-tech.com",
        address1: "Address1: Building B1, National Offshore Innovation and Entrepreneurship Base for Overseas Professionals, No.2 Wanshou Road, Jinan, Shandong, China.",
        home: "Website: www.guanyun-tech.com",
        address2: "Address2: F15, Building 3, Future Entrepreneurship Plaza, Jinan Innovation Zone, Shandong, China. ",
        copyright: "Copyright © GuanYun-Tech 2020-2025. All rights reserved. GuanYun Technology, All rights reserved. ICP License: Lu ICP No. 20016345",
        follow: "Follow official account",
    },
    banner: {
        fast: "Fast",
        MillisecondResponse: 'Millisecond Response',
        precise: 'Precise',
        accuracy: '99% Accuracy',
        sensitive: 'Sensitive',
        micronlevel: 'Micron Scale',
        msg1: 'Aspire to be a pioneer in the field of intelligent monitoring',
        msg2: 'Leading technology and strong posture',
        msg3: 'GuanYun Technology, ',
        msg4: 'Footsteps never cease, Target star Ocean',
        msg5: 'New Materials+Internet of Things+Big Data+Artificial Intelligence',
        msg6: 'Technology is flourishing, and times are changing rapidly',
    },
    home: {
        ServiceScenarios: "Service Scenarios",
        Service1: "Manufacturing Digitization",
        Service1_: "Utilizing sensor and IoT technologies to achieve visualized monitoring of production lines, deeply integrating traditional mechanical equipment with information systems, optimizing production processes through data collection and analysis, and realizing automation, digitization, and lean manufacturing in the production process. ",
        Service2: "Bridge and Tunnel Infrastructure Monitoring",
        Service2_: "China is a bridge construction powerhouse but weak in maintenance. Real-time monitoring of different types of bridge construction can improve maintenance efficiency and enhance disaster resistance and emergency response capabilities. ",
        Service3: "Sports Performance Monitoring",
        Service3_: "Precisely capturing and monitoring movements in many sports can help implement intelligent assessment analysis and closed-loop training applications, thereby scientifically improving athletes' sports performance. ",
        Service4: "High-risk Posture Monitoring",
        Service4_: "Falling is the leading cause of injury for people over 65, and certain occupational groups (such as manual laborers) face high-risk postures in specific environments. Monitoring and protection are highly beneficial for them. \"CompanyIntroduction: \"Company Introduction",
        CompanyIntroduction: "Company Introduction",
        CompanyIntroduction1: "Shandong GuanYun Technology Co., Ltd., also known as \"GuanYun Technology (GuanYun-Tech)\", is headquartered in Jinan City, Shandong Province, P.R. China since 2020. Originating from Smartenius Inc. incorporated in USA, it is founded by a team of doctoral/postdoctoral degrees holders. GuanYun Technology has been recognized as \"Innovative Enterprise in Shandong Province\" and \"National High-Tech Enterprise in China\". ",
        CompanyIntroduction2: "The company's primary business to design and manufacture novel smart sensing systems that can leverage new materials, artificial intelligence, and big data technologies into the monitoring of operation and maintenance of various key infrastructures and critical equipment, therefore address social issues due to the aging of structures. In 2021, GuanYun Technology expanded the application of products into areas such as wearable devices and medical instruments, committed to addressing health issues related to aging and protecting against high-risk human actions. The long-term goal is to achieve real-time motion monitoring, posture recognition and reconstruction, aiming at providing solutions for sports, motion capture, medical care, and more. ",
        CompanyIntroduction3: "GuanYun-Tech founders previously received support from US federal funds to commence commercialization activities and the company has won multiple prizes such as the 1st prize in \"China · Jinan New Dynamic International High-Level Talent Innovation and Entrepreneurship Competition\". GuanYun Technology is based in the National Offshore Innovation and Entrepreneurship Base for Overseas Professionals (Jinan), where it has become one of the leading companies in the technology park. Since establishment, the company has raised fundings in total of millions of dollars via VC/PE (such as SDIIT), equity financing, etc. Key members possess overseas study/work experience, of whom 4 hold doctoral/postdoctoral degrees, 2 have been recognized as leading innovative and entrepreneurial talents in Shandong Province, 2 have been awarded as innovative & entrepreneurial talents by Jinan City and other awards. This team is composed of the young Millennial Generation who hold overseas experience, graduate degrees from world well-known universities, extensive R&D and industry skillsets. ",
        CoreTeam: "Core Team",
        CoreTeamIntroduction: "The company has an experienced and forward-thinking core team. The CEO and major shareholders have years of experience in studying, working, innovating, and managing businesses in the United States. All have earned postdoctoral, doctoral, or master's degrees abroad, demonstrating successful experiences in technical research and product commercialization. ",
        CoreTeam1: "Liu Hao, PhD MBA",
        CoreTeam1_: "Founder and CEO",
        CoreTeam2: "Wang Wenrui, PhD",
        CoreTeam2_: "Partner and Chief Data Officer",
        CoreTeam3: "Peng Cheng, PhD",
        CoreTeam3_: "Chief Algorithm Scientist",
        CoreTeam4: "Sun Canhang, Master's Degree",
        CoreTeam4_: "R&D VP and Deputy General Manager",
        CoreTeam5: "Liu Xuan",
        CoreTeam5_: "Director of Product and Marketing",
        CoreTeam6: "Chen Qingwei, Master's Degree",
        CoreTeam6_: "Director of Hardware",
        CoreTeam7: "Dai Hongbo, PhD, Associate Professor",
        CoreTeam7_: "Co-founder and Technical Advisor",
        AdvisorTeam: "Advisor Team",
        AdvisorTeamIntroduction: "Comprising individuals from China, the U.S., and other countries, the advisor team brings rich experience with professional expertise spanning company operations, asset management, technical production, and marketing.",
        AdvisorTeam1: "Chen Yiding",
        AdvisorTeam1_: "Management Direction Advisor",
        AdvisorTeam2: "Deng Haokun, PhD",
        AdvisorTeam2_: "Operations Direction Advisor",
        AdvisorTeam3: "Zhiming Liu, PhD",
        AdvisorTeam3_: "Technology Direction Advisor",
        DevelopmentHistory: "Development History",
        DevelopmentHistoryIntroduction: "Global clientele, international perspective, and cross-disciplinary integration are the requirement for industry development. ",
        HonorsAndRecognitions: "Honors and Recognitions",
        HonorsAndRecognitionsIntroduction: "Along the way, we strive and work hard, with honors accompanying our sweat, moving forward steadfastly. ",
        CompanyAddress: "Company Address",
        CompanyAddressIntroduction: "Shandong, Jinan, China, National Overseas Talents Offshore Innovation and Entrepreneurship Base & Shandong Institutes Of Industrial Technology",
        addr1: "Shandong, China",
        addr1_: " Confucius was born here, Mount Tai rises here, the Yellow River flows into the sea here, and the Olympics set sail here. The heroes of Qilu are famous all over the world, and hospitable Shandong welcomes you. ",
        addr2: "\"Spring City\" Jinan",
        addr2_: "Jinan, known as the \"Spring City\",  is located in the central part of Shandong Province, China. This ancient yet vibrant city is renowned for its rich history, beautiful natural landscapes, and unique cultural heritage.",
        addr3: "National Entrepreneurship Base",
        addr3_: "The National Offshore Innovation and Entrepreneurship Base for Overseas Professionals is a national-level park that supports team research and development and attracts talents from home and abroad. ",
        addr4: "SDIIT",
        addr4_: "Shandong Institutes Of Industrial Technology, as a provincial-level innovative research institution, supports team research and development and attracts talents from both domestic and overseas. ",
    },
    MPM: {
        banner_line1: "SDIIT & GuanYun - Production and Research",
        banner_line2: "Free combination of various motion and action devices",
        banner_line3: "Meeting diversified and personalized monitoring needs",
        title1: "Motion Monitoring Case",
        content1: "Provide you with safe, stable, and accurate monitoring services to improve sports performance. ",
        golf: "Golf",
        tennis: "Tennis",
        fitness: "Fitness",
        title2: "Posture Monitoring and High-risk Motion Recognition",
        content2: "GuanYun Technology has years of experience in monitoring sensor design, providing intelligent wearable designs and better monitoring experiences for global customers. We are adept at problem-solving and seek the best practical solutions with our clients. ",
    },
    SM: {
        banner_line1: "Massive Construction of Infrastructure, Transportation, and Energy Facilities",
        banner_line2: "Leading the Steel Structure Monitoring Market Explosion",
        title1: "Product Architecture of Structural Monitoring",
        t1: "Structural Monitoring Sensor",
        c1: "Independently developed with innovative materials, relying on patented technology to produce high-precision sensors. ",
        t2: "Signal Transmission Module",
        c2: "Integrated with lithium batteries and solar power, capable of wired and wireless transmission. ",
        t3: "Data Analysis System",
        c3: "Locate structural damage, collect environmental information, and visualize the results. ",
        title2: "Enabling Sensors to serve Various Scenarios and Users",
        title3: "Exploring the diversity of structural monitoring, we are still on the road",
    },
    NEWS: {
        banner: "News Center",
        title: "NEWS News Updates",
    },
    JoinUs: {
        banner_line1: "Eager for Talents",
        banner_line2: "We look forward to having you join us",
        job1: "Hardware Development Engineer",
        job1_: "Requirements:  Master's degree or above in Electronic and Electrical Engineering, Telecommunications, Automation, Control, Physics, or related fields. Graduates from 985 and 211 universities, as well as those with overseas education, are preferred. Proficient in hardware development of single-chip microcomputers, STM32, or other digital processing chips; Skilled in using common circuit design software, familiar with electrical characteristics and application development of common communication interfaces such as UART, SPI, CAN, etc. ",
        job2: "Algorithm Development Engineer",
        job2_: "Requirements: Master's degree or above in Mathematics, Computer Science, Information Technology, Telecommunications, or Physics. Graduates from 985 and 211 universities are preferred. Familiarity with sensor data or digital signals, strong mathematical and algorithmic foundations, proficiency in Matlab/Python, TensorFlow/Hive, etc. Priority given to those with awards in national mathematical modeling competitions or overseas study experience. ",
        job3: "Intelligent Hardware Product Manager",
        job3_: "Requirements: Bachelor's degree or above in Electronic Engineering, Telecommunications, Computer Science, or related fields. Graduates from 985 and 211 universities are preferred. Proficient in skills such as product requirement document management and prototype design. Strong business research, requirement collection, and analysis abilities. Familiarity with hardware product development, experience in developing consumer electronics or wearable products is a plus. 3 years or more of project or product development experience. Priority given to those with overseas study experience. ",
        job4: "Backend Development Engineer",
        job4_: "Requirements: Bachelor's degree or above in Computer Science or related fields, with preference for graduates from 985 and 211 universities; Excellent coding skills, good database language writing skills, proficient in mainstream open-source frameworks and middleware, proficient in JAVA, Spring, Mybatis, etc. Familiar with the architecture design, software implementation, and performance optimization of IoT products; 3-5 years or more of relevant development experience; Preference will be given to those with overseas study experience.",
        job5: "Embedded Development Engineer",
        job5_: "Requirements: Bachelor's degree or above in Electronics, Communications, Computer Science, Automation, or related fields, with preference for graduates from 985 and 211 universities or overseas universities. Familiar with embedded software development based on the ARM platform, proficient in programming applications for various common communication interface buses (IC, UART, CAN, etc. ), and embedded program development for network protocols. Proficient in C language, analog circuits, digital circuits, etc. ",
        job6: "Design Engineer",
        job6_: "Requirements: Bachelor's degree or above in Industrial Design or related majors, with preference for graduates from 985 and 211 universities. Proficient in SolidWorks, ProE, Rhino, CorelDraw, Keyshot, and other software. Have a certain understanding of product structure, materials, and processing techniques. Familiar with human-computer interaction and have a deep understanding of branding. Familiar with the structural design of electronic consumer products, and those who have engaged in mobile phone or other 3C product design, and structural component development processes are preferred. ",
        job_: "Salary: Negotiable",
        job__: "Work Location: Jinan, Shandong, China",
        heart1: "From the heart:",
        heart2: "We aspire to thrive amidst an imperfect environment of innovation and entrepreneurship, ",
        heart3: "striving to become a \"unique blossom\" capable of blooming. ",
        heart4: "We respect talent, admire technology, and welcome challenges and perseverance in innovation. ",
        heart5: "We refuse to being a local small business owner; dreaming of changing the world, no matter the scale, is always essential. ",
        heart6: "From 0 to 1, to more, to infinity. ",
        heart7: "From North America to Asia, from south to north, we stand side by side, our dreams never fading. ",
        heart8: "Along the way, there have been thorns and difficulties, but it's our curiosity, exploration, action, ",
        heart9: "and dreams that have helped us break through. ",
        heart10: "If you resonate with our vision, if you're curious about the world, if you wish to contribute to ",
        heart11: "exploring technology, ",
        heart12: "Don't hesitate, join us!",
        heart13: "We are eager to progress together with the best of you!",
        interested: "I'm Interested",
        company_email: "Company Email:",
        company_email_detail: "info@guanyun-tech.com",
        send_email: "Submit Email:",
        send_email_detail: "hr@guanyun-tech.com",
        company_home: "Company Website:",
        company_home_detail: "www.guanyun-tech.com",
        company_address: "Company Address:",
        company_address_detail: "F15, Building 3, Future Entrepreneurship Plaza, Jinan Innovation Zone, Shandong, China",
        title: "Contact Information",
        input_form: "Complete the Form Below",
        form_tip: "We will contact you as soon as possible. ",
        name: "Name",
        name_hint: "Please enter your name",
        phone: "Phone",
        phone_hint: "Please enter your phone number",
        email: "Email",
        email_hint: "Please enter your email",
        consultation: "Consultation",
        consultation_hint: "Please enter your consultation",
        upload: "Upload Resume",
        drag: "Drag and drop file here or ",
        click: "Click to Upload",
        submit: "Submit",
        wrong_format:"Wrong format",
        fill_one:"Please fill in at least one item. ",
        name_is_empty:"The name cannot be empty. ",
        file_success:"Resume uploaded successfully",
        form_success:"Thanks for your support. We will contact you as soon as possible. ",
    },
    product: {
        GuanYunTechnology: 'GuanYun Technology',
        msg1: 'To provide you with safe, stable, and accurate monitoring services',
        tag1: 'Smart Protective Vest [Connected Edition]',
        tag2: 'Smart Protective Vest [Bluetooth Edition]',
        tag4: 'Smart Protective Belt [Connected Edition]',
        tag5: 'Smart Protective Belt [Bluetooth Edition]',
        jidian: "ZenAlert APP",
        jidianOb: "ZenAlert-Observer",
        pricetit: 'Official guide price:',
        tit1: 'High precision sensing',
        tit2: "Advanced attitude algorithm",
        tit3: 'APP real-time monitoring',
        tit4: 'Active fall protection',
        tit5: 'Millisecond scale fast protection',
        msg2: 'The cloud-like soft airbag can achieve protective effects within 150 milliseconds, protecting the hips and buttocks of the human body, reducing the injuries caused by falls. ',
        tit6: 'Carbon dioxide cylinder',
        msg3: 'Wearable gas cylinders can rapidly release stored carbon dioxide gas to inflate protective airbags. As an accessory to the Intelligent Protection, the gas cylinder needs to be replaced after the protective airbag has been deployed for protection. ',
        tit7: 'Observer APP',
        msg4: 'Display information about the operation of smart wearable devices, the current behavioral status of the wearer, and any abnormal warnings. Additionally, it can perform device binding, parameter settings, and family member management functions, truly realizing care for high-risk individuals.',
        tit8: "Wearer's APP",
        msg5: 'Display the operation information and abnormal alert information of smart wearable products. Using smartphones to communicate data with smart devices for data, we can collect more comprehensive information about the wearer\'s behavior, thus providing better protection and support for them.',
        tit9: 'Family member management',
        msg6: "After binding the device, a virtual family centered on the wearer can be created, and other family members can be invited to join to jointly view the wearer's related status. ",
        tit10: 'Alert notifications',
        msg7: "If the wearer experiences any abnormal situation, such as falling, actively triggering an alert, leaving the designated safe area, or having a low battery on the device, corresponding alert notifications will be received within the APP. ",
        tit11: 'Real-time location tracking',
        msg8: "In the location module, you can view the wearer's location information. After clicking the navigation button, you can also navigate to the wearer's geographical location. ",
        tit12: "Trajectory and guarding range",
        msg9: "After binding the device, you can set the protected area. On the wearer's details page, you can observe the wearer's movement trajectory information and the protected area. ",
        tag3: 'Motion Recognition',
        tit13: 'To provide you with safe, stable, and accurate monitoring services',
        tit14: 'Motion Recognition (under development)',
        msg10: 'As an auxiliary product of intelligent protective, it can achieve high-precision posture capture, providing more accurate and precise data support for fall protection. ',
        tit15: 'Accurate motion capture',
        msg11: 'Utilizing high-precision sensor calibration methods, it can accurately capture various postures of the human body and perform data analysis. ',
        tit16: 'Humanized wearing design',
        msg12: 'The sensor is fixed to the base using a rotating buckle, which is convenient and quick to use. Additionally, a nylon elastic strap is employed, providing strong compatibility for different body sizes and enabling one-handed operation. ',
        tit17: 'A Leader in the Field of',
        tit18: 'Intelligent Monitoring',
        msg13: 'According to research findings, ',
        msg14: 'The annual fall rate among elderly people in communities is',
        msg15: 'In global elderly fall-related accidents, hip fractures account for',
        msg16: 'with lingering sequelae, ',
        msg17: 'leading to complications and death, ',
        msg18: 'resulting in loss of mobility. ',
        msg19: 'Hip joint',
        msg20: 'fracture population',
        msg21: 'Fall monitoring',
        msg22: 'One-touch alarm',
        msg23: 'Precise location tracking',
        msg24: 'Magnetic fast charging',
        tit19: 'Ultra-slim Design with Rivet Closure',
        msg25: 'With a thickness of just 22mm and a weight of 130g, the device is lightweight and comfortable to wear. Additionally, the belt adopts a rivet closure system for easier operation. ',
    },
    factory: {
        title1: "Vibration Sensor",
        sensorInfo: "The wireless temperature and vibration sensor is an industrial-grade intelligent sensor specifically designed for equipment condition monitoring and fault diagnosis. The sensor integrates powerful edge computing capabilities to wirelessly transmit equipment status to cloud platform software in real time. ",
        sensorInfo1: "Collect vibration data",
        sensorInfo2: "Identify equipment faults",
        sensorInfo3: "Real-time monitoring of equipment status",
        title2: "Cloud Service Platform",
        platformInfo: "As a cloud service platform supporting hardware sensor devices, it utilizes Internet of Things (IoT) and cloud computing technologies to provide remote management for sensor devices. The platform enables data collection, analysis, cloud storage, and visual presentation. Additionally, it fully utilizes the powerful computing capabilities of the cloud to perform intelligent analysis on vast amounts of sensor data, providing important reference for predictive maintenance of monitoring devices. ",
        platformInfo1: "Data visualization",
        platformInfo1_: "The platform leverages the powerful computing capabilities of the cloud to perform intelligent analysis on vast amounts of sensor data, providing crucial reference for predictive maintenance of monitoring equipment. ",
        platformInfo2: "Device management",
        platformInfo2_: "The platform enables the management of gateways, devices, and physical quantities, and implements user authorization, ensuring that different users only see content within their respective permissions. ",
    }
}