import Vue from "vue"; // 引入Vue
import en from './en-US' //英语 - 美国
import zh from './zh-CN' //中文
import VueI18n from "vue-i18n"; // 引入国际化的包
Vue.use(VueI18n); // 全局注册国际化包
// 准备翻译的语言环境信息
const lang = navigator.language || navigator.userLanguage
if (lang == 'zh-CN') {
    localStorage.setItem('lang', 'zh')
} else {
    localStorage.setItem('lang', 'en')
}
const i18n = new VueI18n({
    locale: lang == 'zh-CN' ? 'zh' : 'en', // 从Storage中获取语言类型 获取不到就是中文
    messages: {
        zh,   // 中文语言包
        en,  // 英文语言包
    }
});
export default i18n