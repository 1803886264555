function judgeBrand(sUserAgent) {
    var isIphone = sUserAgent.match(/iphone/i) == "iphone";
    var isHuawei = sUserAgent.match(/huawei/i) == "huawei";
    var isHonor = sUserAgent.match(/honor/i) == "honor";
    var isOppo = sUserAgent.match(/oppo/i) == "oppo";
    var isOppoR15 = sUserAgent.match(/pacm00/i) == "pacm00";
    var isVivo = sUserAgent.match(/vivo/i) == "vivo";
    var isXiaomi = sUserAgent.match(/mi\s/i) == "mi ";
    var isXiaomi2s = sUserAgent.match(/mix\s/i) == "mix ";
    var isRedmi = sUserAgent.match(/redmi/i) == "redmi";
    var isSamsung = sUserAgent.match(/sm-/i) == "sm-";

    if (isIphone) {
        return 'iphone';
    } else if (isHuawei || isHonor) {
        return 'huawei';
    } else if (isOppo || isOppoR15) {
        return 'oppo';
    } else if (isVivo) {
        return 'vivo';
    } else if (isXiaomi || isRedmi || isXiaomi2s) {
        return 'xiaomi';
    } else if (isSamsung) {
        return 'samsung';
    } else {
        return 'default';
    }
}
(function (doc, win) {
    var brand = judgeBrand(navigator.userAgent.toLowerCase())//判断机型
    //      用原生方法获取用户设置的浏览器的字体大小(兼容ie)

    if (doc.documentElement.currentStyle) {

        var user_webset_font = doc.documentElement.currentStyle['fontSize'];

    }

    else {

        var user_webset_font = getComputedStyle(doc.documentElement, false)['fontSize'];

    }

    //      取整后与默认16px的比例系数

    var xs = parseFloat(user_webset_font) / 16;

    //      设置rem的js设置的字体大小

    var view_jsset_font, result_font;

    var docEl = doc.documentElement,

        resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',

        clientWidth,

        recalc = function () {

            clientWidth = docEl.clientWidth;

            if (!clientWidth) return;

            if (!doc.addEventListener) return;

            if (clientWidth < 750) {

                //              设置rem的js设置的字体大小

                view_jsset_font = 100 * (clientWidth / 750);

                //              最终的字体大小为rem字体/系数

                result_font = view_jsset_font / xs;

                //              设置根字体大小

                docEl.style.fontSize = result_font + 'px';

            }

            else {
                //因为只适配移动端所以全部采用系数计算字体        
                // docEl.style.fontSize = 100 + 'px';

                //              设置rem的js设置的字体大小

                view_jsset_font = 100 * (clientWidth / 750);

                //              最终的字体大小为rem字体/系数

                result_font = view_jsset_font / xs;

                //              设置根字体大小

                docEl.style.fontSize = result_font + 'px';

            }

        };

    win.addEventListener(resizeEvt, recalc, false);

    doc.addEventListener('DOMContentLoaded', recalc, false);

})(document, window);    