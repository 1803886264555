import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/guanyun',
    name: 'home',
    component: () => import("@/views/HomePage.vue")
  },
  {
    path: '/news_detail',
    name: 'news_detail',
    component: () => import("@/views/NewsDetailsPage.vue")
  },
  {
    path: '/addus',
    name: 'addus',
    component: () => import("@/views/Addus.vue")
  },
  {
    path: '/news',
    name: 'adnewsdus',
    component: () => import("@/views/NewsListPage.vue")
  },
  {
    path: '/',
    name: 'product',
    component: () => import("@/views/ProductPage.vue")
  },
  {
    path: '/sports',
    name: 'sports',
    component: () => import("@/views/SportsPage.vue")
  },
  {
    path: '/struct',
    name: 'struct',
    component: () => import("@/views/StructPage.vue")
  },
  {
    path: '/zhgc',
    name: 'zhgc',
    component: () => import("@/views/zhgc.vue")
  },
  {
    path: '/dzsb',
    name: 'dzsb',
    component: () => import("@/views/dzsb.vue")
  },
]

const router = new VueRouter({

  base: process.env.BASE_URL,
  routes
})

export default router
