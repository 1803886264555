import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import '@/util/rem'
import "swiper/dist/css/swiper.css"
import "swiper"
import Vant from 'vant';
import 'vant/lib/index.css';
import Vconsole from 'vconsole'
import i18n from "@/asset/lang/index";
Vue.prototype.$eventbus = new Vue()
Vue.config.productionTip = false
Vue.use(Element)
Vue.use(Vant)
// let vConsole = new Vconsole()
// Vue.use(vConsole)
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
