<template>
  <div class="root">
    <div class="con">
      <img
        class="icon"
        src="https://www.guanyun-tech.com/img/Logo.png"
        alt=""
      />

      <div class="menus_text" :lang="$i18n.locale">{{ $t('head.menu') }}</div>
      <img
        v-if="!showmenus"
        @click="showmenus = true"
        class="menus"
        src="https://www.guanyun-tech.com/img/Menus.png"
        alt=""
      />
      <img
        v-if="showmenus"
        @click="showmenus = false"
        class="menus"
        src="https://www.guanyun-tech.com/img/close.png"
        alt=""
      />
      <div class="lang" >
        <div  :class="[lang == 'zh' ? 'menuac' : '']"  @click="changeLang('zh')" >中文</div>
        <div>&emsp;/&emsp;</div>
        <div  :class="[lang == 'en' ? 'menuac' : '']"  @click="changeLang('en')">EN</div>
      </div>
      <menus  v-if="showmenus" @close="close"></menus>
    </div>
  </div>
</template>

<script>
import menus from './menus.vue';
export default {
  name: "WebsiteHead",
  data() {
    return {
      showmenus: false,
      lang: localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh'
    }
  },
  components: { menus },


  mounted() { },

  methods: {
    close() {
      this.showmenus = false
    },
    changeLang(e) {
      localStorage.setItem('lang', e)
      this.lang = e
      this.$i18n.locale = e   // 设置当前语言
      switch (this.$store.state.title) {
        case '首页':
          this.$store.commit("setTitle", this.$t('head.homepage'));
          break;
        case 'Home Page':
          this.$store.commit("setTitle", this.$t('head.homepage'));
          break;
        case '智能防护':
          this.$store.commit("setTitle", this.$t('head.ZhishenIntelligentProtectiveBelt'));
          break;
        case 'Smart Protection':
          this.$store.commit("setTitle", this.$t('head.ZhishenIntelligentProtectiveBelt'));
          break;
        case '动作捕捉':
          this.$store.commit("setTitle", this.$t('head.MotionCapture'));
          break;
        case 'Motion Capture':
          this.$store.commit("setTitle", this.$t('head.MotionCapture'));
          break;
        case '智慧工厂':
          this.$store.commit("setTitle", this.$t('head.SmartFactory'));
          break;
        case 'Smart Factory':
          this.$store.commit("setTitle", this.$t('head.SmartFactory'));
          break;
        case '运动监测':
          this.$store.commit("setTitle", this.$t('head.MotionOrPostureMonitoring'));
          break;
        case 'Motion & Posture Monitoring':
          this.$store.commit("setTitle", this.$t('head.MotionOrPostureMonitoring'));
          break;
        case '结构监测':
          this.$store.commit("setTitle", this.$t('head.StructuralMonitoring'));
          break;
        case 'Structural Monitoring':
          this.$store.commit("setTitle", this.$t('head.StructuralMonitoring'));
          break;
        case '新闻中心':
          this.$store.commit("setTitle", this.$t('head.NewsCenter'));
          break;
        case 'News Center':
          this.$store.commit("setTitle", this.$t('head.NewsCenter'));
          break;
          break;
        default:
          break;
      }
      console.log(this.$store.state.title)
      this.$eventbus.$emit('changelang', e)
    }
  },
};
</script>

<style scoped>
.lang {
  font-size: .18rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #f9f9fa;
  margin-left: .26rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: .1rem;
}

.menu {
  font-size: .16rem;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #f9f9fa;
  margin-left: .66rem;
}

.menuac {

  color: #6699ff;
}

.menus_text {
  color: white;
  font-size: 0.25rem;
  font-weight: 800;
  width: 0.69rem;
  height: 0.33rem;
  margin-left: 3rem;
  margin-top: 0.25rem;
}

.menus_text:lang(en) {
  color: white;
  font-size: 0.25rem;
  font-weight: 800;
  width: 0.69rem;
  height: 0.33rem;
  margin-left: 2.5rem;
  margin-top: 0.27rem;
  margin-right: .3rem;
}

.menus {
  width: 0.39rem;
  height: 0.33rem;
  margin-top: 0.28rem;
  margin-bottom: 0.28rem;

}

.root {
  width: 100%;
}

.con {
  width: 100%;
  background-color: #000000;
  height: 1rem;
  display: flex;
  position: relative;
}

.icon {
  width: 1.38rem;
  height: 0.48rem;
  margin-left: 0.44rem;
  margin-top: 0.2rem;
}
</style>