<template>
    <div class="mu_con">
        <div class="mu_item" v-for="(item, index) in menus" :key="index">
            <div @click="goPage(item)"
                :class="[item.hasChildren ? 'mu_tit_h' : $store.state.title == item.name ? 'mu_active' : 'mu_tit']">{{
                item.name }}
                <img v-if="item.hasChildren" :class="[item.showChildren ? 'child_img_ac' : 'child_img']"
                    src="../asset/img/menus/zd.png" alt="">
            </div>
            <template v-if="item.hasChildren && item.showChildren">
                <div class="sub_con">
                    <div class="sub_mu" v-for="(child, index) in item.children" :key="index" @click="goPage(child)">
                        <div :class="[$store.state.title == child.name ? 'active' : 'su_nomal']">{{ child.name }}</div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: 'menus',
    data() {
        return {
            lang: localStorage.getItem('lang') ? localStorage.getItem('lang') : 'zh'
        }
    },
    created() {
        this.$eventbus.$on('changelang', (e) => {
            this.lang = e
            this.findAct(this.menus)
        })
    },
    methods: {
        goPage(item) {
            if (item.hasChildren) {
                item.showChildren = !item.showChildren
                this.$forceUpdate()
                return
            }
            this.$router.push(
                {
                    path: item.path
                }
            )
            this.$emit('close')
        },
        findAct(menus) {
            let that = this
            menus.forEach(meu => {
                if (meu.hasChildren) {
                    meu.children.forEach(sub => {
                        if (sub.name == this.$store.state.title) {
                            meu.showChildren = true
                            this.$forceUpdate()
                        }
                    })
                }
            });
        }
    },
    mounted() {
        this.findAct(this.menus)
    },
    computed: {
        menus() {
            if (this.lang == 'zh') {
                return [
                    {
                        name: this.$t('head.productcenter'),
                        path: "/product",
                        hasChildren: true,
                        showChildren: false,
                        children: [
                            {
                                name: this.$t('head.ZhishenIntelligentProtectiveBelt'),
                                path: "/",
                                hasChildren: false
                            },
                            {
                                name: this.$t('head.MotionOrPostureMonitoring'),
                                path: "/sports",
                                hasChildren: false
                            },
                            {
                                name: this.$t("head.StructuralMonitoring"),
                                path: "/struct",
                                hasChildren: false
                            },

                        ]
                    },
                    
                    {
                        name: this.$t('head.homepage'),
                        path: "/guanyun",
                        hasChildren: false,
                        showChildren: false
                    },
                    {
                        name: this.$t('head.NewsCenter'),
                        path: "/news",
                        hasChildren: false,
                        showChildren: false

                    },

                ]
            } else {
                return [
                {
                        name: this.$t('head.productcenter'),
                        path: "/product",
                        hasChildren: true,
                        showChildren: false,
                        children: [
                            {
                                name: this.$t('head.ZhishenIntelligentProtectiveBelt'),
                                path: "/",
                                hasChildren: false
                            },
                            {
                                name: this.$t('head.MotionOrPostureMonitoring'),
                                path: "/sports",
                                hasChildren: false
                            },
                            {
                                name: this.$t("head.StructuralMonitoring"),
                                path: "/struct",
                                hasChildren: false
                            },

                        ]
                    },
                    
                    {
                        name: this.$t('head.homepage'),
                        path: "/guanyun",
                        hasChildren: false,
                        showChildren: false
                    }

                ]
            }


        }
    }

}
</script>

<style lang="scss" scoped>
.mu_active {
    color: #6699ff;
    font-size: .32rem;
    font-family: Source Han Sans SC;
    font-weight: 500;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.mu_con {
    width: 100%;
    height: 95.2vh;
    box-sizing: border-box;
    background-color: #FFFFFF;
    position: fixed;
    z-index: 999;
    padding: .25rem .44rem;
    top: 4.8vh;

    .mu_item {
        width: 100%;
        padding: .28rem;
        border-bottom: 1px #F0F0F0 solid;
        box-sizing: border-box;

        .child_img {
            width: .13rem;
            height: .24rem;
            float: right;
        }

        .child_img_ac {
            width: .13rem;
            height: .24rem;
            float: right;
            transform: rotate(90deg)
        }

        .mu_tit {
            font-size: .32rem;
            font-family: Source Han Sans SC;
            font-weight: 500;
            color: #333333;
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .mu_tit_h {
            font-size: .32rem;
            font-family: Source Han Sans SC;
            font-weight: 500;
            color: #333333;
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .mu_tit:hover {
            color: #6699ff;
        }



    }



    .sub_con {
        margin-top: .53rem;
        box-sizing: border-box;

        .sub_mu {
            padding: .16rem 0rem;
            padding-left: 1.1rem;
            font-size: .28rem;
            font-family: Source Han Sans SC;
            font-weight: 500;
            color: #666666;
        }

        .active {
            color: #6699ff;
        }


        .su_nomal {
            color: #666666;

        }

        .sub_mu:hover {
            color: #6699ff;
        }

    }


}
</style>