// zh-CN.js
export default {
    head: {
        menu: "菜单",
        homepage: "关于我们",

        productcenter: "产品中心",
        ZhishenIntelligentProtectiveBelt: "智能防护",
        MotionCapture: "动作捕捉",
        SmartFactory: "智慧工厂",
        MotionOrPostureMonitoring: "运动监测",
        StructuralMonitoring: "结构监测",
        NewsCenter: '新闻中心',
        JoinUs: '加入我们'
    },
    footer: {
        email: "邮箱：info@guanyun-tech.com",
        address1: "地址1：济南市市中区万寿路2号B1座国家海外人才离岸创新创业基地",
        home: "网址：www.guanyun-tech.com",
        address2: "地址2：中国（山东）自由贸易试验区济南片区未来创业广场3号楼",
        copyright: "Copyright © GuanYun-Tech 2020-2025. All rights reserved. 版权所有。 ICP证：鲁ICP备20016345号",
        follow: "关注公众号"
    },
    banner: {
        fast: '快速',
        MillisecondResponse: '毫秒级响应',
        precise: '精准',
        accuracy: '99%精度',
        sensitive: '灵敏',
        micronlevel: '微米级别',
        msg1: '立志做智能监测领域的领航者',
        msg2: '技术领先，强者姿态',
        msg3: '我们，观云智能，',
        msg4: '脚步从未停止，目标星辰大海',
        msg5: '新材料+物联网+大数据+人工智能',
        msg6: '科技蓬勃发展，时代日新月异'
    },
    home: {
        ServiceScenarios: "服务场景",
        Service1: "制造业数字化",
        Service1_: "借助传感器与物联网技术实现产线可视化监控, 将传统机械设备与信息系统深度融合，通过数据采集与分析优化生产流程,实现生产过程的自动化、数字化、精益化。",
        Service2: "桥隧基建监测",
        Service2_: "我国是桥梁大国，却又是管养弱国；对于不同类型桥梁基建的实时监测可以提升管养效率，加强其抗灾应急能力。",
        Service3: "体育运动监测",
        Service3_: "对于许多体育运动项目的动作进行精确捕捉与监测，有助于实施智能测评分析及训练应用闭环，从而科学提升运动员的运动水平",
        Service4: "高危姿态监测",
        Service4_: "跌倒是65岁以上人群受伤的主要原因，特定环境下部分职业人群 （搬运工人等）的姿态存在高危风险，对他们的相应监测及防护 大有裨益。",
        CompanyIntroduction: "公司简介",
        CompanyIntroduction1: "观云（山东）智能科技有限公司，即“观云智能”，总部位于山东省济南市，前身为美国Smartenius Inc.科技公司，是由美国海归博士及博士后团队创建、山东省与济南市政府重点引进的高科技企业，先后获评科技型中小企业、山东省创新型中小企业、国家高新技术企业。",
        CompanyIntroduction2: "公司主要业务是设计制造新型智能传感器系统，基于新材料、人工智能和大数据分析等，面向各类大中型结构和关键设备开展运维监测，解决基建老化带来的社会问题。2021年起，观云智能拓展新型传感器在人体可穿戴设备和医用仪器等领域的广阔应用，致力于解决老龄化带来的人群健康问题，开发用于人体高危动作监测与防护的可穿戴式智能设备，实现面向老年人高危动作进行“事后快速报警、事前准确预警、即时提供保护”的多功能路线，长期目标是实现人体全域动作监测、实时运动识别和肢体姿态重构，为运动训练、动作捕捉、医疗康养等提供解决方案。",
        CompanyIntroduction3: "创始人于2016年获美国联邦基金支持在美开始从事商业化，2017年初在美成立科技公司，2019年获第二届“中国·济南新动能国际高层次人才创新创业大赛”海外赛区一等奖，2020年下半年落地济南正式运营，已获2023年山东省泰山产业领军人才创业类支持、2022年山东省泰山产业领军人才创新类支持、2021年国家人社部中国留学人员回国创业启动支持计划优秀项目、2020年山东省人社厅留学人员来鲁创业启动支持计划培育类第一名、第三届“创业齐鲁·共赢未来”山东省高层次人才创业大赛优胜奖、第9届中国创新创业大赛全国总决赛优秀企业、山东赛区暨2020年山东省中小微企业创新竞技行动计划初创组全省第二名、2020年度“创客中国”山东省中小企业创新创业大赛总决赛优胜奖、工信部5G应用征集大赛专题赛三等奖等荣誉。核心队伍中多人拥有海外学习工作经历、4人最高学历为博士或博士后、2人次获评山东省泰山产业领军人才、2人次获评山东省济南市“5150引才倍增计划”创新创业人才（海右人才）、另有多人获评各级人才称号，形成一支以海归硕士博士牵头、985及211毕业生为核心、创新创业经验丰富的90后科技团队。公司在中国科协国家海外人才离岸创新创业基地（济南）注册落地，成为首批入驻企业，此外还曾获山东产业技术研究院数百万元项目投资和数百平米场地支持，累计获创新创业资金及股权融资等千万余元。",
        CoreTeam: "核心团队",
        CoreTeamIntroduction: "公司拥有经验丰富、理念先进的核心团队，CEO和主要控股人员均拥有多年的美国求学工作、创新创业、经营管理经历，均于海外获博士后、博士、硕士等学位，具备成功的技术研发和产品商业化等经验",
        CoreTeam1: "刘昊 工学博士 MBA",
        CoreTeam1_: "创始人及CEO",
        CoreTeam2: "王文瑞 博士",
        CoreTeam2_: "合伙人及数据总监",
        CoreTeam3: "彭程 博士 教授职称",
        CoreTeam3_: "首席算法科学家",
        CoreTeam4: "孙灿杭 硕士",
        CoreTeam4_: "研发VP、副总",
        CoreTeam5: "刘宣",
        CoreTeam5_: "产品及市场总监",
        CoreTeam6: "陈庆为 硕士",
        CoreTeam6_: "硬件总监",
        CoreTeam7: "戴宏波 博士 副教授职称",
        CoreTeam7_: "联合创始人及技术顾问",
        AdvisorTeam: "顾问团队",
        AdvisorTeamIntroduction: "来自中美等国，具备丰富经验，专业特长涵盖公司运营、资产管理、技术生产及市场营销等",
        AdvisorTeam1: "陈宜顶",
        AdvisorTeam1_: "管理方向顾问",
        AdvisorTeam2: "邓昊昆 博士",
        AdvisorTeam2_: "运营方向顾问",
        AdvisorTeam3: "Zhiming Liu 博士",
        AdvisorTeam3_: "技术方向顾问",
        DevelopmentHistory: "发展历程",
        DevelopmentHistoryIntroduction: "全球客户、国际视野、跨界整合是行业发展的要求；跨国消费市场+关联性人才合作=我们的不懈追求",
        HonorsAndRecognitions: "荣誉表彰",
        HonorsAndRecognitionsIntroduction: "一路走来，我们努力拼搏，荣誉伴随着我们的汗水，踏实前行",
        CompanyAddress: "公司地址",
        CompanyAddressIntroduction: "中国山东省济南市，国家海外人才离岸创新创业基地&山东产业技术研究院",
        addr1: "中国山东",
        addr1_: "孔子在这里诞生，泰山在这里崛起，黄河在这里入海，奥运在这里扬帆，齐鲁好汉名天下，好客山东欢迎您。",
        addr2: "“泉城”济南",
        addr2_: "大明湖、趵突泉、千佛山，四面荷花三面柳，一城山色半城湖，这里有琼瑶笔下的夏雨荷，也有老舍居住的冬天。",
        addr3: "国家级创业基地",
        addr3_: "国家海外人才离岸创新创业基地为国家级园区，助力团队研发、吸引海内外人才。",
        addr4: "山东产研院",
        addr4_: "山东产业技术研究院，为省级新型研发机构，助力团队研发、吸引海内外人才。",
    },
    MPM: {
        banner_line1: "SDIIT&GuanYun-产研观云",
        banner_line2: "多种运动及动作设备的自由组合",
        banner_line3: "满足多样化、个性化的监测需求",
        title1: "运动监测案例",
        content1: "为您提供安全、稳定、精确的监测服务，助力运动水平提升。",
        golf: "高尔夫",
        tennis: "网球",
        fitness: "健身",
        title2: "姿态监测及高危动作识别",
        content2: "观云智能拥有多年监测传感器设计经验，为全球客户提供智能穿戴设计和更好的监测体验。我们擅长解决问题，并与客户一起寻求最佳的实用解决方案。",
    },
    SM: {
        banner_line1: "基建、交通、能源设施的海量建设",
        banner_line2: "引领钢结构监测市场大爆发",
        title1: "结构监测的产品架构",
        t1: "结构监测传感器",
        c1: "自主研发全新材料，依靠专利技术制备高精度传感器",
        t2: "信号传输模块",
        c2: "集成锂电与太阳能，具备实现有线、无线传输功能",
        t3: "数据分析系统",
        c3: "定位结构损伤，汇总环境信息，实现结果可视化",
        title2: "让传感器更好地面向各类场景、服务广大用户",
        title3: "探索结构监测的多样性，我们仍然在路上",
    },
    NEWS: {
        banner: "新闻中心",
        title: "NEWS新闻动态",
    },
    JoinUs: {
        banner_line1: "求贤若渴",
        banner_line2: "拓土开疆，希望有您",
        job1: "硬件开发工程师",
        job1_: "要求：电子电气、通讯、自动化、控制、物理等硕士以上学历，985及211、海外学历毕业生优先；精通单片机、stm32或其他数字处理芯片的硬件开发；熟练使用常用电路设计软件，熟悉UART、SPI、CAN等常用通信接口的电气特性与应用开发。",
        job2: "算法开发工程师",
        job2_: "要求：数学、计算机或信息技术、通讯及物理等专业硕士以上学历，985及211毕业生优先；熟悉传感器数据或数字信号，扎实的数学功底与算法基础，精通Matlab/Python、TensorFlow/Hive等；有全国数学建模比赛等获奖、有海外留学经历者优先。",
        job3: "智能硬件产品经理",
        job3_: "要求：电子、通信、计算机类本科以上学历，985及211毕业生优先；熟练掌握产品需求文档、原型图设计等技能，较强的业务调研、需求收集与分析能力；熟悉硬件产品开发，有消费类电子产品或可穿戴类产品开发经验优先；3年及以上项目或产品开发经验；有海外留学经历者优先。",
        job4: "后端开发工程师",
        job4_: "要求：计算机类本科以上学历，985及211毕业生优先；优秀的编码能力，良好的数据库语言编写能力，熟练使用主流开源框架和中间件，精通JAVA、Spring、Mybatis等，熟悉IoT物联网产品的架构设计、软件实现和性能优化等；3-5年及以上开发经验；有海外留学经历者优先。",
        job5: "嵌入式开发工程师",
        job5_: "要求：电子、通讯、计算机、自动化等本科以上学历，985及211、海外学历毕业生优先；熟悉基于ARM平台的嵌入式软件开发、熟练掌握常见各种通信接口总线（IC、UART、CAN等）编程应用、网络协议的嵌入式程序开发，精通C语言、模拟电路、数字电路等。",
        job6: "设计工程师",
        job6_: "要求：工业设计类专业本科以上学历，985及211毕业生优先；精通SolidWorks、ProE、Rhino、CorelDraw、Keyshot等软件；对产品结构、材料和加工工艺有一定了解；熟悉人机交互，对品牌有较深认识；熟悉电子消费产品结构设计，从事过手机或其他3C类产品设计、结构件开发流程优先。",
        job_: "薪水：面议",
        job__: "工作地点：山东济南",
        heart1: "来自心里的话：",
        heart2: "立志于从不完美的创新创业环境中生长，去做一朵可以绽放的“奇葩”。",
        heart3: "尊重人才，崇尚技术，欢迎对技术的battle，欢迎对创新的执著。",
        heart4: "不想做一个local小商贩，改变世界的梦想，无论大小，总是要有的。",
        heart5: "每一次个人分享会，可以尽情分享兴趣爱好、梦里花落知多少，可以无关技术、无关工作。",
        heart6: "每一次团聚的机会，喜悦不是来自中餐西餐或菜系美味，而是有你、有我、有他。",
        heart7: "我们分享每个人的重要时刻，也经历整个团队的共同成长。",
        heart8: "From 0 to 1, to more, to infinity.",
        heart9: "从北美到亚洲，从南到北，我们并肩作战，梦想从未停止。",
        heart10: "一路走来荆棘遍布，唯有好奇心、探索欲、行动力和梦想，相伴我们破釜沉舟。",
        heart11: "如果您认同我们的理念，如果您对这个世界充满好奇，如果您想为探索科技尽一份力，",
        heart12: "别犹豫，加入我们吧!",
        heart13: "我们，愿与最好的您携手共进!",
        interested: "我感兴趣",
        company_email: "公司邮箱：",
        company_email_detail: "info@guanyun-tech.com",
        send_email: "简历投递邮箱：",
        send_email_detail: "hr@guanyun-tech.com",
        company_home: "公司网址：",
        company_home_detail: "www.guanyun-tech.com",
        company_address: "公司地址：",
        company_address_detail: "中国（山东）自由贸易试验区济南片区未来创业广场",
        title: "联系方式",
        input_form: "填写下方表单",
        form_tip: "我们将第一时间与您取得联系",
        name: "姓名",
        name_hint: "请输入姓名",
        phone: "手机号",
        phone_hint: "请输入手机号",
        email: "邮箱",
        email_hint: "请输入邮箱",
        consultation: "咨询内容",
        consultation_hint: "请输入咨询内容",
        upload: "上传简历",
        drag: "将文件拖到此处，或 ",
        click: "点击上传",
        submit: "立即提交",
        wrong_format:"格式有误",
        fill_one:"请至少填写一项",
        name_is_empty:"姓名不能为空",
        file_success:"简历上传成功",
        form_success:"感谢您对我们的支持，我们会尽快与您取得联系",
    },
    product: {
        GuanYunTechnology: '观云智能',
        msg1: '为您提供安全、稳定、精确的监测服务',
        tag1: '智能防护马甲【联网款】',
        tag2: '智能防护马甲【蓝牙款】',
        tag4: '智能防护腰带【联网款】',
        tag5: '智能防护腰带【蓝牙款】',
        jidian: "迹点APP",
        jidianOb: "迹点APP-守护者端",
        pricetit: '官方指导价',
        tit1: '高精度传感',
        tit2: '先进姿态算法',
        tit3: 'APP实时监护',
        tit4: '跌倒主动防护',
        tit5: '毫秒级快速保护',
        msg2: '云感柔软气囊，在150毫秒内可以达到保护效果，保护人体的髋部及臀部，减轻跌倒所造成的伤害。',
        tit6: '二氧化碳气瓶',
        msg3: '可穿戴式气瓶，能够快速释放内部储存的二氧化碳气体，为防护气囊充气；智绅智能防护可穿戴设备配件，防护气囊展开保护后，需对气瓶进行更换。',
        tit7: '守护者端',
        msg4: '展示智能可穿戴产品的设备运行信息、佩戴者当前行为状态信息以及异常告警信息等，同时可进行设备绑定、参数设置及家庭成员管理等功能，真正实现对高危人群的关爱。',
        tit8: '佩戴者端',
        msg5: '展示智能可穿戴产品的设备运行信息等，借助手机媒介与智能设备进行数据通信，更加完整的采集行为者信息，为佩戴者保驾护航。',
        tit9: '家庭成员管理',
        msg6: ' 设备绑定后，即可生成佩戴者为主体的虚拟家庭，可邀请其他家庭成员加入，共同查看佩戴者相关状况。',
        tit10: '告警提醒',
        msg7: ' 佩戴者出现异常情况，如佩戴者跌倒、主动告警、走出守护范围、设备电量过低，都会在APP内收到相应告警提醒。',
        tit11: '实时定位',
        msg8: '在定位模块可查看佩戴者的位置信息，点击导航按钮后也可以导航前去佩戴者所在的地理位置。',
        tit12: '轨迹及守护范围',
        msg9: '设备绑定后，可对守护范围进行设置，在佩戴者详情页可观测到佩戴者的行动轨迹信息及守护范围。',
        tag3: '动作识别',
        tit13: '为您提供安全、稳定、精确的监测服务',
        tit14: '动作识别（研发中）',
        msg10: ' 作为智能防护可穿戴设备的辅助产品，可做到高精度的姿态捕捉，为摔倒防护提供更精准、确切的数据支持。',
        tit15: '精准动作捕捉',
        msg11: '采用高精度的传感器标定方式，能精准捕捉人体的各个姿态，并进行数据分析。',
        tit16: '人性化穿戴',
        msg12: '传感器与底座之间采用旋转卡扣固定，方便快捷。同时采用尼龙松紧绑带，对不同人体尺寸的兼容性强，可实现单手操作。',
        tit17: '智能监测',
        tit18: '领域的引导者',
        msg13: '据调查研究发现',
        msg14: '每年社区老年人跌倒率为',
        msg15: '在全世界老年人摔伤事故中，髋关节骨折占',
        msg16: '留有后遗症',
        msg17: '因并发症死亡',
        msg18: '行动无法自理',
        msg19: '髋关节',
        msg20: '骨折人群',
        msg21: '跌倒检测',
        msg22: '一键报警',
        msg23: '精准定位',
        msg24: '磁吸快充',
        tit19: '机身轻薄 铆钉扣合',
        msg25: '机身薄至22mm，轻至130g，佩戴无压力。同时腰带采用铆钉扣扣合方式，操作更便捷。',
    },
    factory: {
        title1: "振动传感器",
        sensorInfo: "无线温振传感器是一款专为设备状态监测与故障诊断设计的工业级智能传感器。传感器集成强大的边缘计算功能，及时将设备状态无线发送到云平台软件。",
        sensorInfo1: "采集振动数据",
        sensorInfo2: "识别设备故障",
        sensorInfo3: "实时监测设备状态",
        title2: "云服务平台",
        platformInfo: "作为硬件传感器设备的配套云服务平台，基于物联网和云计算技术，能够对传感器设备提供远程管理，并实现数据的采集、分析、云端存储及可视化呈现等功能。同时，平台充分利用云端强大的计算能力，对海量的传感器数据进行智能分析，为监测设备的预测性维护提供重要参考依据。",
        platformInfo1: "数据可视化",
        platformInfo1_: "平台充分利用云端强大的计算能力，对海量的传感器数据进行智能分析，为监测设备的预测性维护提供重要参考依据。",
        platformInfo2: "设备管理",
        platformInfo2_: "平台可实现网关、设备、物理量的管理，并实现用户分权，不同用户仅看到各自权限下的内容。",
    },
}